import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Parser } from "html-to-react";

const Faq = ({ data }) => {
  const htmlToReactParser = new Parser();
  return (
    <>
      <section className="privacy_sec">
        <Container>
          <div className="inner_heading mb-3">
            <span className="ml-3">Help lah</span>
            <h2 className="ml-3">Faq</h2>
          </div>

          <div className="faq_content">
            <Row>
              <Col lg={12}>{htmlToReactParser.parse(data)}</Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default React.memo(Faq);
