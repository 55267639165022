import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import images
import { btnAndriodImg, btnIosImg, footerLogoImg } from "../assets/images";
function Footer() {
  return (
    <div>
      <section className="app-stripe">
        <Container>
          <p className="m-0 pb-2 text-center lead_text">
            What are waiting for! <strong>Download App</strong>
          </p>
          <div className="d-flex mx-n1 justify-content-center">
            <a
              href="https://www.apple.com/in/app-store/"
              target="_blank"
              className="px-1"
            >
              <img src={btnAndriodImg} />
            </a>
            <a
              href="https://play.google.com/store/apps"
              target="_blank"
              className="px-1"
            >
              <img src={btnIosImg} />
            </a>
          </div>
        </Container>
      </section>

      <footer>
        <Container>
          <Row>
            <Col col={12} md={6}>
              <div className="footer-content">
                <Link to="/" className="footer_logo m-0">
                  <img src={footerLogoImg} />
                </Link>
                <p className="m-0 py-3 pb-md-5 pt-md-3">
                  Help Lah is the easiest way to engage local service
                  professionals for your home and office. We are regarded as
                  Singapore leading mobile app for instant booking local service
                  professionals.
                </p>

                <div className="title text-uppercase pb-3">Connect With us</div>
                <ul className="d-flex social-link">
                  <li>
                    <a href="#">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fab fa-pinterest-p"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col col={12} md={6}>
              <div className="d-md-flex flex-md-wrap ml-md-auto footer-info pt-4 pt-md-0">
                <div className="title pb-3">Contact Us</div>
                <address className="d-flex align-items-start">
                  <i class="fas fa-envelope"></i>
                  <div className="pl-3">
                    <p className="m-0">Support@helplah.com</p>
                    <p className="m-0">+6588337702</p>
                    <p className="m-0">Monday to Friday</p>
                    <p className="m-0">10:00am - 6:00pm</p>
                    <p className="m-0">
                      (Excluding Saturday ,Sunday & Public Holidays)
                    </p>
                  </div>
                </address>

                <address className="d-flex align-items-start">
                  <i class="fas fa-map-marker-alt"></i>
                  <div className="pl-3">
                    <p className="m-0">
                      721 Clementi West Street 2 #01-118 Singapore 120721
                    </p>
                  </div>
                </address>
              </div>
            </Col>
            <Col className="copyright">
              <p className="m-0">
                COPYRIGHT © 2021 Help Lah .Pte. Ltd. ALL RIGHTS RESERVED Help
                Lah and the help Lah logo are trademarks of Help Lah. Pte. Ltd.
                (202026746C). All other trademarks are the trademarks of their
                respective owners.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;
