import React, { useEffect, useState } from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/App.css";
import "./assets/css/Media.css";

import Header from "./component/Header";
import Footer from "./component/Footer";
import ScrollToTop from "./component/ScrollToTop";
import Home from "./pages/Home";
import Inquiry from "./pages/Inquiry";
import Faq from "./pages/Faq";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import Http from "./helper/Http.js";

function App() {
  const [faq, setFaq] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [about, setAbout] = useState("");

  const getData = async () => {
    let path = "admin/getContent";
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        return resJson;
      } else {
      }
    } else {
      console.log(res);
    }
  };

  useEffect(() => {
    getData().then((data) => {
      if (data?.result?.faq) {
        setFaq(data?.result?.faq);
      }
      if (data?.result?.privacy_policy) {
        setPrivacyPolicy(data?.result?.privacy_policy);
      }
      if (data?.result?.about_us) {
        setAbout(data?.result?.about_us);
      }
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Header />
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About data={about} />
          </Route>
          <Route exact path="/inquiry">
            <Inquiry />
          </Route>
          <Route exact path="/faq">
            <Faq data={faq} />
          </Route>
          <Route exact path="/privacy">
            <Privacy data={privacyPolicy} />
          </Route>
        </Switch>
      </ScrollToTop>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
