import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Parser } from "html-to-react";
// import images
import { privacyImg } from "../assets/images";

const Privacy = ({ data }) => {
  const htmlToReactParser = new Parser();

  return (
    <>
      <section className="privacy_sec">
        <Container>
          <Row>
            <Col lg={7}>
              <div className="inner_heading mb-3">
                <span className="ml-3">Help lah</span>
                <h2 className="ml-3">About Us</h2>
              </div>

              <div className="privacy_content">
                {htmlToReactParser.parse(data)}
              </div>
            </Col>
            <Col lg={5} className="d-flex align-items-center">
              <div className="privacy_banner">
                <img src={privacyImg} alt="privacy" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Privacy;
