import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Http from "../helper/Http.js";

function Inquiry() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [user_type, setUserType] = useState("");
  const [service_type, setServiceType] = useState("");
  const [message, setMessage] = useState("");
  const [categorylist, setcategorylist] = useState([]);
  const notify = (ms) => toast();
  useEffect(() => {
    getcategorylist();
  }, []);

  async function getcategorylist() {
    let path = "admin/categoryDropDownList";
    const res = await Http("GET", path);
    const resJson = await res.json();
    if (resJson.status === 200) {
      if (resJson.status) {
        console.log(resJson, "category");
        setcategorylist(resJson.result);
      } else {
        console.log(res.message);
      }
    } else {
      console.log(res.message);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const reqData = new FormData();
    reqData.append("name", name);
    reqData.append("email", email);
    reqData.append("number", number);
    reqData.append("user_type", user_type);
    reqData.append("service_type", service_type);
    reqData.append("message", message);

    let path = "admin/sendInquiry";
    const res = await Http("POST", path, reqData);
    if (res.status === 200) {
      if (res.status) {
        setEmail("");
        setName("");
        setNumber("");
        setServiceType("");
        setUserType("");
        setMessage("");
        document.getElementById("inquiry-form").reset();
        toast.success(
          "Thanks for connect with us! we will get back to you soon.",
          { theme: "colored" }
        );
      } else {
        toast.error("Something went wrong !", { theme: "colored" });
      }
    } else {
      toast.error("Something went wrong !", { theme: "colored" });
    }
  };

  return (
    <div>
      <div className="inner_body_section">
        <div className="banner_service"></div>
        <div className="inquiry_sec">
          <Container>
            <Row>
              <Col md={8} className="mx-auto inquiry_form bg-white">
                <div className="title text-center">Inquiry Now</div>

                <form onSubmit={onSubmit} id="inquiry-form">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="form-control"
                      placeholder="Mobile No."
                      onChange={(e) => {
                        setNumber(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      as="select"
                      className="form-contro custom-select"
                      onChange={(e) => {
                        setUserType(e.target.value);
                      }}
                      required
                    >
                      <option value="">Select User Type</option>
                      <option value="Customer">Customer</option>
                      <option value="Provider">Provider</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      as="select"
                      className="form-contro custom-select"
                      onChange={(e) => {
                        setServiceType(e.target.value);
                      }}
                      required
                    >
                      <option value="">Select service type</option>
                      {categorylist.map((category_data) => (
                        <option
                          key={category_data._id}
                          value={category_data.name}
                        >
                          {category_data.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      s
                      placeholder="Message"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="pt-3">
                    <button
                      type="submit"
                      className="btn btn_large w-100 rounded-0"
                    >
                      SUBMIT
                    </button>
                  </Form.Group>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Inquiry;
