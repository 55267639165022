import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import images
import { logoImg } from "../assets/images";
//javascript scroll function
document.getElementById("OnscrollBody").onscroll = function () {
  var element = document.getElementById("navbar_scroll_fixed");
  var scroll = window.scrollY;
  if (scroll <= 20) {
    element.classList.remove("fixed-top");
  } else {
    element.classList.add("fixed-top");
  }
};

const requestSort = () => {
  window.scrollTo(0, 0);
};

function Header() {
  return (
    <header id="navbar_scroll_fixed" className="navigation inner_header">
      <Navbar expand="lg">
        <Container>
          <Link to="/" className="logo m-0">
            <img src={logoImg} />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto align-items-lg-center d-flex">
              <NavLink
                className="nav-link"
                to="/"
                title="Home"
                exact
                activeClassName="active"
              >
                Home
              </NavLink>
              <NavLink
                className="nav-link"
                to="/about"
                exact
                activeClassName="active"
              >
                About us
              </NavLink>
              <NavLink
                className="nav-link"
                to="/faq"
                exact
                activeClassName="active"
              >
                Faq
              </NavLink>
              <NavLink
                className="nav-link"
                to="/privacy"
                exact
                activeClassName="active"
              >
                Privacy
              </NavLink>
              <NavLink
                className="nav-link"
                to="/inquiry"
                exact
                activeClassName="active"
              >
                Inquiry
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
