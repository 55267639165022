import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
// import images
import {
  pointerImg,
  btnAndriodImg,
  btnIosImg,
  whereImg,
  timeImg,
  deviceImg,
  about1Img,
  service1Img,
  service2Img,
  service3Img,
  service4Img,
  service5Img,
  service6Img,
  service7Img,
  videoImg,
} from "../assets/images";

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    dots: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section className="home_banner_block">
        <Container className="h-100">
          <div className="banner_content ">
            <div className="banner_text">
              <h1 className="banner_title pb-1">
                <span className="color-red">Save</span> Time & Money
              </h1>
              <p className="banner-text m-0 text-center px-2 px-md-0">
                Help around the home is just a few{" "}
                <img src={pointerImg} className="px-1" /> clicks away{" "}
              </p>
            </div>
            <div className="banner_btn pt-3 pt-md-0">
              <p className="m-0 pb-2 lead_text">
                What are waiting for! <strong>Download App</strong>
              </p>
              <div className="d-flex mx-n1">
                <a
                  href="https://www.apple.com/in/app-store/"
                  target="_blank"
                  className="px-1"
                >
                  <img src={btnAndriodImg} alt="" />
                </a>
                <a
                  href="https://play.google.com/store/apps"
                  target="_blank"
                  className="px-1"
                >
                  <img src={btnIosImg} alt="" />
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* about_sec */}

      <section className="about_sec">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              <div className="heading_sec">
                <h2 className="text-white">
                  The easiest way to engage a{" "}
                  <span className="text-dark">
                    reliable service professional
                  </span>{" "}
                  at
                </h2>
              </div>
              <div className="about_content">
                <ul>
                  <li>
                    <img src={timeImg} />
                    <h4>
                      Any <span className="text-dark">Time</span>
                    </h4>
                  </li>
                  <li>
                    <img src={whereImg} alt="" />
                    <h4>
                      Any <span className="text-dark">Where</span>
                    </h4>
                  </li>
                  <li>
                    <img src={deviceImg} alt="" />
                    <h4>
                      Any <span className="text-dark">Device</span>
                    </h4>
                  </li>
                </ul>
                <h3 className="text-center text-white">
                  We can <span className="text-dark">FIX IT!!</span>
                </h3>
              </div>
            </Col>

            <Col xs={12} md={4} className="about_right">
              <div className="about_img">
                <img src={about1Img} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* about_sec */}

      {/* service_sec */}

      <section className="service_sec">
        <Container>
          <div className="heading_sec text-center pb-3 pb-md-4">
            <h2 className="title text-black">Our Services</h2>
          </div>
          <Row className="no-gutters service_list">
            <Col xs={6} sm={4} lg={3}>
              <div className="box">
                <img src={service1Img} alt="service" />
                <h3 className="title">Handyman</h3>
              </div>
            </Col>
            <Col xs={6} sm={4} lg={3}>
              <div className="box">
                <img src={service2Img} alt="service" />
                <h3 className="title">Electrical Repair</h3>
              </div>
            </Col>
            <Col xs={6} sm={4} lg={3}>
              <div className="box">
                <img src={service3Img} alt="service" />
                <h3 className="title">Carpentry Repair</h3>
              </div>
            </Col>
            <Col xs={6} sm={4} lg={3}>
              <div className="box">
                <img src={service4Img} alt="service" />
                <h3 className="title">Aircon Servicing</h3>
              </div>
            </Col>

            <Col xs={6} sm={4} lg={3}>
              <div className="box">
                <img src={service5Img} alt="service" />
                <h3 className="title">Plumbing</h3>
              </div>
            </Col>

            <Col xs={6} sm={4} lg={3}>
              <div className="box">
                <img src={service6Img} alt="service" />
                <h3 className="title">Painting</h3>
              </div>
            </Col>

            <Col xs={6} sm={4} lg={3}>
              <div className="box">
                <img src={service7Img} alt="service" />
                <h3 className="title">Home Cleaning </h3>
              </div>
            </Col>

            <Col xs={6} sm={4} sm={4} lg={3}>
              <div className="box">
                <a href="3" className="d-flex align-items-center">
                  <h4> & Much More </h4>
                  <span>
                    {" "}
                    <i class="fas fa-arrow-right"></i>{" "}
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* service_sec */}

      {/* work_sec */}

      <section className="work_sec">
        <Container>
          <div className="heading_sec text-center pb-3 pb-md-4">
            <h2 className="title text-black">How it Works</h2>
          </div>

          <div className="text-center video_box">
            <video width="750" height="500" controls controlsList="nodownload">
              <source src="Helplah.mp4" type="video/mp4" alt="vvvv" />
            </video>
          </div>
        </Container>
      </section>

      {/* work_sec */}

      {/* testmonial_sec */}

      <section className="testimonial_sec">
        <Container>
          <div className="heading_sec text-center  pb-3 pb-md-4">
            <h2 className="title text-black">Testimonial</h2>
          </div>
          <Row>
            <Col lg="10" className="m-auto">
              <Slider {...settings}>
                <div className="test_slider">
                  <h4>PARQUET FLOOR RE-VARNISH</h4>
                  <p>
                    <span>“Fast respond and Excellent Service.</span> Appreciate
                    the works done. Look as good as new flooring!”
                  </p>
                  <div className="star">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                  <h6>Ms Lin, St. Michael’s Road</h6>
                </div>

                <div className="test_slider">
                  <h4>ELECTRICAL POWER FAILURE</h4>
                  <p>
                    <span>“Fast respond and reasonable price.</span> Appreciate
                    the works done. I will use his service in the next future!”
                  </p>
                  <div className="star">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                  <h6>Ms Ivy Hew, Teban Gardens</h6>
                </div>

                <div className="test_slider">
                  <h4>ELECTRICAL POWER FAILURE</h4>
                  <p>
                    <span>“A BIG THANK TO HELP LAH!</span> Really appreciate the
                    painting job done and your personal attendance too. Will
                    Spread the word around for Help Lah!!”
                  </p>
                  <div className="star">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                  <h6>Mr Tan, Toa Payoh</h6>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>

      {/* testmonial_sec */}
    </>
  );
};

export default Home;
